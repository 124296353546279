export const faNginx = {
    prefix: "fab",
    iconName: "nginx",
    icon: [
      24,
      24,
      [],
      null,
      "M22.5,18V6L12,0L1.5,6v12L12,24L22.5,18z M8.636,16.589c0,0.721-0.576,1.29-1.287,1.29H7.273c-0.728,0-1.303-0.6-1.303-1.29V7.41c0-0.705,0.636-1.29,1.515-1.29c0.653,0,1.394,0.255,1.848,0.81l6.03,7.141V7.41c0-0.721,0.606-1.29,1.303-1.29h0.076c0.727,0,1.303,0.6,1.303,1.29v9.18h0.015c0,0.705-0.653,1.29-1.544,1.29c-0.637,0-1.365-0.255-1.819-0.81L8.636,9.929V16.589z"
    ]
  };
  